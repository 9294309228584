//
// Video BG
// --------------------------------------------------


// Custom Styles

.vide_bg {
  width: 100%;

  .container {
    position: relative;
    z-index: 2;
  }
}

.video-bg-overlay {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, .2);
    z-index: 1;
  }
}