//
// Boxes
// --------------------------------------------------
.box-icon-image {
	transition: 280ms ease-out;

	&:hover {
		transform: translateY(-3px);
		filter: drop-shadow(-8px 8px 2px rgba($black, .2));
	}
}