/*
*
* Material Parallax
*/

.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
}

.ipad, .iphone {
  .parallax-container {
    background-attachment: scroll !important;
  }
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  transform: translate3d(-50%, 0, 0);
  max-width: none;
}

.parallax-content {
  position: relative;
  z-index: 1;
  
  .container {
    position: relative;
  }

  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    background-color: rgba($black, .4);
    z-index: 0;
  }
}
