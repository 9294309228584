//
// Posts
// --------------------------------------------------

// Post classic
//

* + .post-classic-body,
.post-classic-title + * {
  margin-top: 20px;
}

@include media-breakpoint-up(md) {
  * + .post-classic-body,
  .post-classic-title + * {
    margin-top: 30px;
  }
}

* + .post-meta,
* + .post-classic-footer,
* + .post-minimal-footer {
  margin-top: 20px;
}

* + .post-classic-title {
  margin-top: 25px;
}

* + .carousel-post-gallery,
* + .entry-video,
* + .post-link,
* + .post-quote,
* + .post-audio,
* + .img-thumbnail-variant-1 {
  margin-top: 25px;
}

@include media-breakpoint-up(md) {
  * + .carousel-post-gallery,
  * + .entry-video,
  * + .post-link,
  * + .post-quote,
  * + .post-audio,
  * + .img-thumbnail-variant-1 {
    margin-top: 40px;
  }
}

.post-classic-title a {
  color: $black;
  &:hover {
    color: $primary;
  }
}

.post-classic-title-icon {
  position: relative;

  &[class*="linear-icon-"]:before {
    display: inline-block;
    float: left;
    width: 30px;
    margin-right: 8px;
    height: 30px;
    vertical-align: baseline;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
}

.post-classic-footer {
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    * + .button.button-link {
      margin-top: 0;
    }
  }
}

.post-meta {
  font-size: 14px;
  font-style: italic;
}

.meta-author {
  color: $gray;
  &:hover {
    color: $primary;
  }
}

// Post minimal
//

.post-minimal {
  text-align: left;
  
  img {
    display: block;
    width: 100%;
  }

  * + .post-meta {
    margin-top: 10px;
  }

  * + .post-classic-body {
    margin-top: 20px;
  }

  * + [class*=list-inline-] {
    margin-top: 20px; 
  }

  * + & {
    margin-top: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }
}

.post-minimal-image {
  position: relative;
  transition: 320ms ease-in-out;
  
  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    display: inline-block;
    background-color: rgba($black, .1);
    //filter: brightness(120%) hue-rotate(45deg);
  }
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: $shadow-area-lg;
  }
}

.post-minimal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * + .button {
    margin-top: 25px;
  }
}