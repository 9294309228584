// canvas animation
//
.particles-js-wrap {
  position: relative;
  .container {
    position: relative;
  }
}
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; 
}
