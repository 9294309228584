//
// Layouts
// --------------------------------------------------

// Footer corporate,
.pre-footer-corporate,
.footer-corporate {
  font-size: 14px;
  line-height: (24 / 14);
}

.pre-footer-corporate {
  position: relative;
  padding: 50px 0;

  h6 + *,
  .heading-6 + * {
    margin-top: 18px;
  }
}

.footer-corporate {
  padding: 20px 0;
  text-align: center;
}

.footer-corporate .footer-corporate__inner {
  > * + * {
    margin-top: 5px;
  }
}

@include media-breakpoint-up(md) {
  .footer-corporate {
    text-align: left;

    .footer-corporate__inner {
      > * + * {
        margin-top: 0;
      }
    }
  }

  .footer-corporate__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;

    > * {
      padding: 0 15px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .pre-footer-corporate {
    padding: 80px 0;

    h6 + *,
    .heading-6 + * {
      margin-top: 22px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .pre-footer-corporate {
    line-height: (26 / 16);
  }
}


@media (min-width: map-get($grid-breakpoints, xl)) and (min-height: $screen-height-desktop) {
  .pre-footer-corporate {
    padding: 120px 0;
  }
}